.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  background-color: #a697ff;
  border: none;
  color: white;
}

.btn:hover {
  background-color: #a697ff;
  opacity: 0.8;
}

.input {
  color: black;
}

.dropdown-btn {
  background-color: white;
  color: black;
  border-color: black;
}

.dropshadow-label {
  background-color: white;
  color: black;
  opacity: 40%;
  width: 100%;
  justify-content: left;
}

.dropshadow-label:hover {
  background-color: white;
}

.alert {
  width: auto;
  /* top: 0;
  right: 0; */
  margin-right: 15px;
  margin-top: 15px;
  /* position: absolute; */
}

.alert-container {
  top: 0;
  right: 0;
  position: absolute;
  width: auto;
  display: flex;
  flex-direction: column;
  column-gap: 4px;
  z-index: 10;
}
